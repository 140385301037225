import React from "react";
import Typed from "react-typed";
import me from "../assets/me.png";

const Title = () => {
  return (
    <article className="flex flex-row items-center justify-evenly p-8 ">
      <img src={me} alt="Andreas Cross" className='rounded-full h-28 shadow-slate-800 shadow-md sm:h-96'/>
      <Typed
        className="text-xl p-5 sm:text-5xl"
        strings={[
          "Good Da",
          "Hey, I",
          "Hi, I'm Andreas Oliver Cross.",
          "I'm a Full-Stack Developer",
        ]}
        typeSpeed={50}
        backSpeed={50}
      />
    </article>
  );
};

export default Title;
