import React, { useContext } from "react";
import { PortContext } from "../context/PortContext";
import { FiGithub } from "react-icons/fi";
import chatbox from "../assets/chatbox.png";
import flightfinder from "../assets/flightfinder.png";
import daintree from "../assets/daintree.png";
import tictactoe from "../assets/tictactoe.png";
import uno from "../assets/uno.png";

const Projects = () => {
  const { isOn } = useContext(PortContext);
  return (
    <section className="grid grid-cols-1 gap-2 mb-10 p-3 sm:gap-4 sm:ml-4 sm:mr-4 sm:p-8 sm:grid-cols-3">
        <article
        className={
          !isOn
            ? "bg-gray-800 p-3 sm:p-10 border-gray-800 rounded shadow-md shadow-slate-800"
            : "bg-gray-100 p-3 sm:p-10 border-gray-100 rounded shadow-md"
        }
      >
        <div className="flex flex-row justify-between mb-2">
          <a href="https://silver-macaron-ef4571.netlify.app" target="_blank"
          rel="noopener noreferrer">
            <h2 className="font-bold text-lg">Daintree</h2>
          </a>
          <a href="https://github.com/HandyAndyOG/Daintree" target="_blank"
          rel="noopener noreferrer">
            <FiGithub />
          </a>
        </div>
        <p>
          E-commerce site. Three possible roles with different permissions (user, admin and super-admin).
        </p>
        <a
          href="https://silver-macaron-ef4571.netlify.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="rounded mt-2 mb-2"
            src={daintree}
            alt="Daintree home page"
          ></img>
        </a>
        <p>
          <span className="font-semibold">Tech Stack: </span>ReactJS,
          TypeScript, expressJS, NodeJS, Tailwind, SQLlite
        </p>
      </article>
      <article
        className={
          !isOn
            ? "bg-gray-800 p-3 sm:p-10 border-gray-800 rounded shadow-md shadow-slate-800"
            : "bg-gray-100 p-3 sm:p-10 border-gray-100 rounded shadow-md"
        }
      >
        <div className="flex flex-row justify-between mb-2">
          <a href="https://main--fancy-kleicha-2d2d93.netlify.app/" target="_blank"
          rel="noopener noreferrer">
            <h2 className="font-bold text-lg">UNO</h2>
          </a>
          <a href="https://github.com/HandyAndyOG/uno" target="_blank"
          rel="noopener noreferrer">
            <FiGithub />
          </a>
        </div>
        <p>
          UNO! We all know this classic, enjoy playing it with 3 of your friends.
        </p>
        <a
          href="https://main--fancy-kleicha-2d2d93.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="rounded mt-2 mb-2"
            src={uno}
            alt="Uno home page"
          ></img>
        </a>
        <p>
          <span className="font-semibold">Tech Stack: </span>ReactJS, Socket.io, ExpressJS, NodeJS, Firebase
        </p>
      </article>
      <article
        className={
          !isOn
            ? "bg-gray-800 p-3 sm:p-10 border-gray-800 rounded shadow-md shadow-slate-800"
            : "bg-gray-100 p-3 sm:p-10 border-gray-100 rounded shadow-md"
        }
      >
        <div className="flex flex-row justify-between mb-2">
          <a href="https://celadon-lokum-b1c273.netlify.app" target="_blank"
          rel="noopener noreferrer">
            <h2 className="font-bold text-lg">Tic Tac Toe</h2>
          </a>
          <a href="https://github.com/HandyAndyOG/TicTacToe-PvP-PvAi-AivAi" target="_blank"
          rel="noopener noreferrer">
            <FiGithub />
          </a>
        </div>
        <p>
          Tic Tac Toe with 3 game modes. Challenge your friend, or play against th machine or let the machines battle it out!
        </p>
        <a
          href="https://celadon-lokum-b1c273.netlify.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="rounded mt-2 mb-2"
            src={tictactoe}
            alt="Tic Tac Toe Home Page"
          ></img>
        </a>
        <p>
          <span className="font-semibold">Tech Stack: </span>ReactJS
        </p>
      </article>
      <article
        className={
          !isOn
            ? "bg-gray-800 p-3 sm:p-10 border-gray-800 rounded shadow-md shadow-slate-800"
            : "bg-gray-100 p-3 sm:p-10 border-gray-100 rounded shadow-md"
        }
      >
        <div className="flex flex-row justify-between mb-2">
          <a
            href="https://ornate-florentine-0b6958.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 className="font-bold text-lg">Chat App</h2>
          </a>
          <a href="https://github.com/HandyAndyOG/chat_application" target="_blank"
          rel="noopener noreferrer">
            <FiGithub />
          </a>
        </div>
        <p>
          The app allows users to create private chat rooms with their friends,
          and once the chat session is closed, the room and its content are
          automatically deleted.
        </p>
        <a
          href="https://ornate-florentine-0b6958.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="rounded mt-2 mb-2"
            src={chatbox}
            alt="Chat App Login Page"
          ></img>
        </a>
        <p>
          <span className="font-semibold">Tech Stack: </span>ReactJS, expressJS,
          NodeJS, Socket.io
        </p>
      </article>
      <article
        className={
          !isOn
            ? "bg-gray-800 p-3 sm:p-10 border-gray-800 rounded shadow-md shadow-slate-800"
            : "bg-gray-100 p-3 sm:p-10 border-gray-100 rounded shadow-md"
        }
      >
        <div className="flex flex-row justify-between mb-2">
          <a href="https://main--jazzy-snickerdoodle-4d4846.netlify.app/" target="_blank"
          rel="noopener noreferrer">
            <h2 className="font-bold text-lg">Flight Finder</h2>
          </a>
          <a href="https://github.com/HandyAndyOG/flightFinder" target="_blank"
          rel="noopener noreferrer">
            <FiGithub />
          </a>
        </div>
        <p>
          Search for flights based on travel times, select one-way or return.
          Add selected flights to a shopping cart, remove them, or proceed to
          checkout.
        </p>
        <a
          href="https://main--jazzy-snickerdoodle-4d4846.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="rounded mt-2 mb-2"
            src={flightfinder}
            alt="Flight Finder Home Page"
          ></img>
        </a>
        <p>
          <span className="font-semibold">Tech Stack: </span>ReactJS,
          TypeScript, expressJS, NodeJS, Tailwind, Mongoose
        </p>
      </article>
      
      
      
    </section>
  );
};

export default Projects;
