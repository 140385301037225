import React,{ useContext} from "react";
import { PortContext } from "../context/PortContext";
import Nav from "./Nav";
import Title from "./Title";
import Projects from "./Projects";

const Home = () => {
  const {isOn} = useContext(PortContext)

  return (
    <section className={!isOn? "bg-gray-700 ease-in-out" : 'bg-gray-100 ease-in-out'}>
      <Nav/>
      <Title />
      <h1 className="font-semibold text-center text-2xl">Featured Projects</h1>
      <Projects />
    </section>
  );
};

export default Home;
