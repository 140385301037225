import React, { useContext } from "react";
import { PortContext } from "../context/PortContext";
import { FiGithub } from "react-icons/fi";
import { SiGmail } from "react-icons/si";
import { BsCode } from "react-icons/bs";
import { IoIosSunny } from "react-icons/io";
import { IoIosMoon } from "react-icons/io";
import { motion } from "framer-motion";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const Nav = () => {
  const { isOn, setIsOn } = useContext(PortContext);
  const toggleSwitch = () => setIsOn(!isOn);
  return (
    <nav
      className={
        !isOn
          ? "bg-gray-800 flex flex-row justify-around p-8 border-b-gray800 shadow-md-md shadow-md-gray-900"
          : "flex flex-row justify-around p-8 border-b shadow-md-md"
      }
    >
      <BsCode />
      <a href="https://github.com/HandyAndyOG" target="_blank" rel="noopener noreferrer">
        <FiGithub />
      </a>
      <a href="mailto: andreasocross@yahoo.com">
        <SiGmail />
      </a>
      <div
        className={
          !isOn
            ? "cursor-pointer w-10 h-[25px] bg-gray-100 flex justify-start rounded-[50px] p-[2.5px] self-end"
            : "cursor-pointer w-10 h-[25px] bg-gray-800 flex justify-end rounded-[50px] p-[2.5px] self-end"
        }
        onClick={toggleSwitch}
      >
        <motion.div
          className={
            !isOn
              ? "w-5 h-5 bg-gray-800 rounded-[40px] flex items-center justify-center"
              : "w-5 h-5 bg-gray-100 rounded-[40px] flex items-center justify-center"
          }
          layout
          transition={spring}
        >
          {isOn ? (
            <IoIosSunny className="h-3" />
          ) : (
            <IoIosMoon className="h-3" />
          )}
        </motion.div>
      </div>
    </nav>
  );
};

export default Nav;
