import React, { useContext } from "react";
import { PortContext } from "./context/PortContext";
import "./App.css";
import Home from "./components/Home";

function App() {
  const { isOn } = useContext(PortContext);
  return (
    <div
      className={
        !isOn ? "text-gray-100 bg-gray-700 ease-in-out h-screen" : "bg-gray-100 text-gray-900 ease-in-out h-screen"
      }
    >
      <Home />
    </div>
  );
}

export default App;
