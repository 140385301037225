import React, {useState, createContext} from 'react'
import { States } from '../Types/types';

export const PortContext = createContext<States>({
    isOn: false,
    setIsOn: () => {}
})

const PortProvider: React.FC<{children: React.ReactElement}> = ({children}) => {
  const [isOn, setIsOn] = useState<boolean>(false);

  return (
    <PortContext.Provider value={{isOn, setIsOn}}>
        {children}
    </PortContext.Provider>
  )
}

export default PortProvider